<template>
    <div class="auth-wrapper auth-v1 px-2">
        <b-modal
      id="modal-scrollable"
      scrollable
      title="使用條款（包括有關私隱政策，免責聲明及版權的條款）"
      size="lg"
      ok-title="關閉"
      ok-only
    >
      <b-card-text
        v-for="(content,index) in scrollContent"
        :key="index"
      >
        {{ content }}
      </b-card-text>
    </b-modal>
    <div class="auth-inner py-2" v-if="success === true">
        <h2 class="brand-text text-primary ml-1 text-center">
              登記成功<br><br> 你的會員號碼 : {{ member_code }}
        </h2>
    </div>
      <div class="auth-inner py-2" v-if="success === false">
        <!-- Register v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">
              {{ client_name }}
            </h2>
          </b-link>
  
          <b-card-title class="mb-1">
            🖊 客人登記表 Client Register Form📋
          </b-card-title>
          <b-card-text class="mb-2">
  
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="registerForm">
            <h4 class="brand-text text-primary ml-1" v-if="valid === false">Invalid Link</h4>
            <b-form v-if="insert === false && valid === true">
              <b-form-group
                label="中文姓名 Chinese Name"
                label-for="zh_name"
              >
                <b-form-input
                  id="zh_name"
                  v-model="member.zh_name"
                  trim
                />
  
              </b-form-group>
              
              <b-form-group
                label="英文姓名 English Name"
                label-for="en_name"
              >
                <b-form-input
                  id="en_name"
                  v-model="member.en_name"
                  trim
                />
  
              </b-form-group>


              <!-- <b-form-group
                label="手提電話 Mobile"
                label-for="mobile"
              >
                <b-form-input
                  id="mobile"
                  type="number"
                  v-model="member.mobile"
                  trim
                />
  
              </b-form-group> -->
              <label> 手提電話 Mobile</label><br>
              <b-input-group class="mb-1">
        <b-form-input v-model="member.mobile"/>
        <b-input-group-append>
          <b-button variant="outline-primary" @click="get_code()">
            取得驗證碼
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-alert
      variant="warning"
      :show="sent_code"
    >
      <div class="alert-body">
      {{message}}
      </div>
    </b-alert>
      <b-form-group
                label="驗證碼 Verification code"
                label-for="code"
                v-if="sent_code === true"
              >
                <b-form-input
                  id="code"
                  v-model="member.code"
                  trim
                />
  
              </b-form-group>



              <b-form-group
                label="聯絡地址 Contact Address"
                label-for="address"
              >
                <b-form-textarea
                  id="address"
                  v-model="member.address"
                  trim
                  rows="3"
                />
  
              </b-form-group>

              <label> 性別 Gender </label><br>
              <b-form-group>
                <b-form-radio-group
                style="display:flex;"
                id="btn-radios-1"
                v-model="member.gender"
                size="lg"
                button-variant="outline-primary"
                :options="[{ text: '男 Male', value: 'M' },{text: '女 Female', value: 'F' }]"
                buttons
                name="radios-btn-default"
                />
            </b-form-group>
               
  
             <b-form-group
               label="出生年月 Day Of Birth (YYYY-MM-DD)"
               label-for="dob"
             >
               <b-form-input
                 id="dob"
                 v-mask="'####-##-##'"
                 v-model="member.birth"
                 trim
               />
  
             </b-form-group>
  
            <b-form-group
              label="聯絡電郵 Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="member.email"
                trim
              />
  
            </b-form-group>
  
            <hr class="my-3"/>
            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                我已閱讀及同意<b-link v-b-modal.modal-scrollable>使用條款（包括有關私隱政策，免責聲明及版權的條款）</b-link>

              </b-form-checkbox>
            </b-form-group>
            <VueSignaturePad ref="signaturePad" height="300px" :options="{ dotSize: 2, throttle: 20,backgroundColor: '#eee',onBegin, onEnd }"/>
            <b-button class="mt-1" block @click="clear()" variant="outline-warning">
                清除簽名 Clear Signature
            </b-button>
            <!-- submit button -->
            <hr class="my-2"/>
  
              <b-button
                variant="primary"
                block
                type="button"
                @click="submit"
              >
                送出 Submit
              </b-button>
            </b-form>
          </validation-observer>
  
  
  
  
        </b-card>
      <!-- /Register v1 -->
      </div>
    </div>
  
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BCard, BLink, BCardTitle, BCardText, BForm,BButtonGroup,BAlert,
    BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,BFormTextarea,
    BFormRadioGroup,
    BFormCheckboxGroup,
  } from 'bootstrap-vue'
  import router from '@/router'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      VuexyLogo,
      // BSV
      BCard,
      BAlert,
      BLink,
      BFormTextarea, BCardText,
      BFormRadioGroup,
    BFormCheckboxGroup,
      BCardTitle,
      router,
      BCardText,
      BButtonGroup,
      BForm,
      BButton,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        scrollContent: [
        `條款及細則`,
      ],
        client_name : router.currentRoute.params.web_key.toUpperCase(),
        regEmail: '',
        sent_code : false,
        username: '',
        valid : true,
        password: '',
        status: '',
        insert: false,
        message: null,
        success: false,
        member_code : null,
        member : {
          member_code: null,
          mobile: null,
          zh_name:null,
          en_name:null,
          address:null,
          gender:null,
          birth:null,
          email: null,
          signature:null
        },
        // validation rules
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    created() {

    },
    methods: {
        get_code() {
            this.$http.post(process.env.VUE_APP_API_BASE+`/register/code/`,{ mobile : this.member.mobile })
            .then(res => {
                if (res.data.result === true ) {
                    this.sent_code = true
                    this.message = res.data.message
                } else {
                    this.message = res.data.message
                }
            })
        },
        submit() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
            this.member.signature = data
            this.$http.post(process.env.VUE_APP_API_BASE+`/register/`+ this.client_name + `/`,this.member)
            .then(res => {
                if (res.data.result === true ) {
                this.success = true
                this.member_code = res.data.member_code
                } else {
                
                }
            })
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
        },
        onBegin() {
        console.log('=== Begin ===');
        },
        onEnd() {
        console.log('=== End ===');
        },

        },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  